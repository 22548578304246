<template>
    <div class="back">
        <div class="back_inner">
            对不起，您所要的页面找不到
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{

        }
    },
    mounted(){

    },
    methods:{
        
    }
}
</script>
<style lang="scss" scoped>
.back{
    display: flex;
    justify-content: center;
    .back_inner{
        width: 90%;
        margin-top: 150px;
        display: flex;
        justify-content: center;
    }
}
</style>
